import { NavLink } from "react-router-dom";
import routes from "../../../routes";

export default function ArticleCards(props) {
  return (
    <NavLink to={routes.articleDetail.replace(":id", props.id)} state={props}>
      <div className="article-card-item">
        <p
          dangerouslySetInnerHTML={{
            __html:
              props?.content?.rendered.match(/<img\s+[^>]*?\/?>/gi)[0] ??
              "<img src='images/article-img.png' alt=''/>",
          }}
        ></p>
        {/* <p
          dangerouslySetInnerHTML={{
            __html: found[0] ?? (
              <img dange src="images/article-img.png" alt="" />
            ),
          }}
        ></p> */}

        <div className="text">
          {/* <p>{props.categories_text}</p> */}
          <h1>
            {props?.title?.rendered ??
              "Hormon Menyusui yang Bikin ASI Melimpah"}
          </h1>
          <p
            dangerouslySetInnerHTML={{
              __html:
                props?.excerpt?.rendered ??
                "Salah satu persiapan penting jelang kelahiran adalah perlengkapan mama menyusui. Yuk, cari tahu perlengkapan yang harus dipersiapkan.",
            }}
          ></p>

          <button className="button-custom">
            Baca Detail
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4325 13.4874C21.8175 13.1025 21.8175 12.4783 21.4325 12.0934L16.1752 6.83605C15.7903 6.4511 15.1661 6.4511 14.7812 6.83605C14.3962 7.22101 14.3962 7.84515 14.7812 8.23011L18.3557 11.8046H4.96357C4.41916 11.8046 3.97783 12.246 3.97783 12.7904C3.97783 13.3348 4.41916 13.7761 4.96357 13.7761H18.3557L14.7812 17.3507C14.3962 17.7356 14.3962 18.3598 14.7812 18.7447C15.1661 19.1297 15.7903 19.1297 16.1752 18.7447L21.4325 13.4874Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </NavLink>
  );
}
