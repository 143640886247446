import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { format, parseISO } from "date-fns";

export default function ArticleDetail() {
  const { state } = useLocation();
  const date = parseISO(state.date);
  const formattedDate = format(date, "MMMM d yyyy");

  return (
    <>
      <div className="wrapper-pages">
        <Navbar />
        <div className="article-details-wrapper">
          <div className="images">
            <img src="/images/banner-article-details.png" alt="" />
          </div>

          <div className="container">
            <div className="desc-wrapper">
              <div className="heading">
                <h5>{state?.categories_text ?? "-"}</h5>
                <h1>{state?.title?.rendered ?? "-"}</h1>
                <h5>Posted on {formattedDate}, by Admin</h5>
              </div>

              <div
                className="desc-body"
                dangerouslySetInnerHTML={{
                  __html: state?.content?.rendered ?? "-",
                }}
              ></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
